
/* SCROLL BAR STYLES */
/* Works on Firefox */
* {
	scrollbar-width: thin;
	scrollbar-color: white white;
}  
/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar { width: 1px; }
*::-webkit-scrollbar-track { background: white; }
*::-webkit-scrollbar-thumb { border-radius: 1px; }