.footer {
	height: min-content;
	color: white;
	min-width: min-content;
	text-align: center;
}
.mpkFooter {
	width: 100vw;
	display: grid;
	grid-template-columns: 2fr;
	grid-template-rows: 1fr 1fr;
	justify-items: normal;
	justify-self: center;
	padding-top: 0px;
	padding-bottom: 0px;
}
.footerItem {
	text-align: center;
	margin-bottom: 0px;
}
.footerItemGrid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	justify-items: center;
}
.footerItemGridItem {
	color: white;
}