@import "https://code.getmdl.io/1.3.0/material.green-light_green.min.css";
@import "~bootstrap/dist/css/bootstrap.css";
@import "https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css";


/* user defined stylesheets */
@import "./stylesheets/buttons.css";
@import "./stylesheets/elements.css";
@import "./stylesheets/footer.css";
@import "./stylesheets/header.css";
@import "./stylesheets/scrollbars.css";
@import "./stylesheets/toast.css";
@import "./stylesheets/faicons.css";


* {
	padding: 0;
	margin: 0;
}

main {
	padding: 1rem;
}

.container {
	max-width: 1170px;
	margin-left: auto;
	margin-right: auto;
	min-width: 320px;
}

app-root {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr auto;
}

.box_shadow {
	/* box-shadow: 1px 1px 8px #888888; */
	box-shadow: 			1px 1px 7px -3px rgba(0,0,0,1);
	-webkit-box-shadow: 	1px 1px 7px -3px rgba(0,0,0,1);
	-moz-box-shadow: 		1px 1px 7px -3px rgba(0,0,0,1);
}